import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  API_URL_CONTENTS,
  API_URL_INPUT_CONTENTS,
} from 'manager/http-constants_key';
import { SUMMARY_DISPLAY_TEMPLATE } from 'manager/template-constant';
import { DISPLAY_INFORMATIONS_API_CONSTANT } from 'src/app/shared/constant/api-constant';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { DeleteDialogService } from 'src/app/shared/html-parts/confirm-dialog/delete-dialog/delete-dialog.service';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { SUMMARY_DISPLAY_CONSTANT } from './constant';
import { SummaryContentDisplayInputComponent } from './summary-content-display-input/summary-content-display-input.component';
import { SummaryDisplayDetailComponent } from './summary-display-detail/summary-display-detail.component';
import { SummaryDisplayService } from './summary-display.service';
import { SummaryDisplayContentService } from './summary-content-display.service';

@Component({
  selector: 'app-summary-content-display',
  templateUrl: './summary-content-display.component.html',
  styleUrls: ['./summary-display.component.scss'],
})

/**
 * サマリーコンテンツ詳細
 */
export class SummaryContentDisplayComponent implements OnInit {
  /** 詳細画面 */
  @ViewChild(SummaryDisplayDetailComponent)
  summaryDisplayDetailComponent: SummaryDisplayDetailComponent;

  /** コンテンツ編集 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /** 回覧入力画面ダイアログ */
  @ViewChild(SummaryContentDisplayInputComponent)
  summaryContentDisplayInputComponent: SummaryContentDisplayInputComponent;

  // インプット.案件ID
  @Input() itemId: string;

  // インプット.コンテンツID
  @Input() contentId: string;

  // インプット.案件作成者ID
  @Input() itemCreateUserId: string;

  // インプット.コンテンツ作成者ID
  @Input() contentCreateUserId: string;

  // 削除コンテンツID
  @Output() deleteContentId = new EventEmitter<string>();

  /* 画面用プロパティ */
  // サマリーコンテンツ目次情報格納先
  summaryContentDisplayList: any[] = new Array();
  // サマリーコンテンツ目次情報行出力数
  summaryRowContentDisplayNumber: number =
    SUMMARY_DISPLAY_CONSTANT.SUMMARY_ROW_CONTENT_DISPLAY_NUMBER;
  // サマリーコンテンツ目次情報列出力数
  summaryColContentDisplayNumber: number[] = new Array();

  // サマリー回覧情報格納先
  summaryCirculationDisplayList: any[] = new Array();

  //承認者リスト情報格納先
  approverList: any[] = new Array();

  //ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  constructor(
    private dbOperationService: DbOperationService,
    private summaryDisplayService: SummaryDisplayService,
    private summaryDisplayContentService: SummaryDisplayContentService,
    private loadingState: LoadingState,
    private commonService: CommonService,
    private deleteDialogService: DeleteDialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // サマリーコンテンツ情報取得処理を実施
    this.getSummaryContentDisplay();

    //承認者リスト取得を実施
    this.GetApproverList();
  }

  /**
   * サマリーコンテンツ情報取得処理
   */
  public getSummaryContentDisplay() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart('getSummaryContentDisplay_' + this.contentId);

    /* サマリーコンテンツ目次情報取得処理(画面用) */
    this.dbOperationService
      .getDisplayData(
        API_URL_CONTENTS,
        SUMMARY_DISPLAY_TEMPLATE.SUMMARY_CONTENT_TITLE_DISPLAY_TEMPLATE_ID,
        this.contentId
      )
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(
          0.3,
          'getSummaryContentDisplay_' + this.contentId
        );

        // サマリーコンテンツ目次情報が存在するか否か
        if (this.commonService.checkNoneResponse(response)) {
          // サマリーコンテンツ目次情報が存在しない場合
          this.summaryContentDisplayList = new Array();

          return;
        }

        // サマリーコンテンツ目次情報の列出力数を計算
        // サマリーコンテンツ目次情報横出力数 = サマリー目次情報 / 設定値の切り上げ
        this.summaryColContentDisplayNumber = Array(
          Math.ceil(
            response.body.length /
              SUMMARY_DISPLAY_CONSTANT.SUMMARY_ROW_DISPLAY_NUMBER
          )
        )
          .fill(0)
          .map((x, i) => i);

        // サマリーコンテンツ目次情報のJSONをオブジェクトに格納する
        this.summaryContentDisplayList = response.body;
      });

    // サマリー回覧リスト取得処理を実施
    this.getSummaryCirculationDisplayList();
  }

  /**
   * サマリー回覧リスト取得処理
   */
  public getSummaryCirculationDisplayList() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(
      'getSummaryCirculationDisplayList_' + this.contentId
    );

    /* 回覧リスト取得処理 */
    this.summaryDisplayService
      .getCirculationsList(this.itemId, this.contentId)
      .subscribe((response) => {
        // 回覧リストが存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 回覧リストのJSONをオブジェクトに格納する
          this.summaryCirculationDisplayList = response.body;
        } else {
          this.summaryCirculationDisplayList = new Array();
        }
      });

    // 画面ロードフラグをOFF(ロード終了)
    this.loadingState.loadSleepEnd(
      0.3,
      'getSummaryCirculationDisplayList_' + this.contentId
    );
  }

  /**
   * 詳細情報画面表示
   */
  protected detail() {
    // 詳細画面表示
    this.summaryDisplayDetailComponent.summaryContentDetail(this.contentId);
  }

  /**
   * 更新ボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  protected updateButtonDisplayFlag(): boolean {
    if (
      this.loginUser.user_id === String(this.contentCreateUserId) ||
      this.loginUser.admit === '1' ||
      this.loginUser.department_code === '99999'
    ) {
      return true;
    }
    return false;
  }

  /**
   * 削除ボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  protected deleteButtonDisplayFlag(): boolean {
    if (
      this.loginUser.user_id === String(this.contentCreateUserId) ||
      this.loginUser.admit === '1' ||
      this.loginUser.department_code === '99999'
    ) {
      return true;
    }
    return false;
  }

  /**
   * 回覧登録ボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  public insertCirculationDisplayFlag(): boolean {
    // 承認者リストにログインユーザが存在するかチェック
    const approver = this.approverList.find((user) => {
      return user.user_id === this.loginUser.user_id;
    });
    //権限チェック
    if (
      this.loginUser.admit === '1' ||
      this.loginUser.department_code === '99999' ||
      this.loginUser.user_id === String(this.contentCreateUserId) ||
      approver
    ) {
      return true;
    }
    return false;
  }

  /**
   * 編集画面表示
   */
  public update() {
    // 編集画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_CONTENTS,
      API_URL_CONTENTS,
      SUMMARY_DISPLAY_TEMPLATE.SUMMARY_CONTENT_EDIT_INPUT_TEMPLATE_ID,
      this.contentId
    );
  }

  /**
   * 削除処理
   */
  protected delete() {
    // 削除ダイアログにより削除処理
    this.deleteDialogService
      .deleteDialog(
        API_URL_CONTENTS,
        this.commonService.getArrayObjectValue(
          this.summaryContentDisplayList,
          DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_PKEY,
          DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_NAME,
          'P'
        ),
        this.contentId
      )
      .subscribe((contentId) => {
        // 削除したコンテンツIDを親画面に返却
        this.deleteContentId.emit(contentId);
      });
  }

  /**
   * 回覧登録ボタン
   */
  public insertCirculation() {
    // 入力画面表示
    this.summaryContentDisplayInputComponent.inputSummaryCirculation(
      this.itemId,
      this.contentId
    );
  }

  /**
   * 回覧状況ボタン
   * @param circulation 回覧情報
   */
  protected circulationStatus(circulation: any) {
    // サマリー回覧状況画面へ遷移
    this.router.navigate(['pages/summary/summary-circulation/1'], {
      queryParams: {
        item_id: this.itemId,
        content_id: this.contentId,
        circulation_id: circulation.circulation_id,
        item_create_user_id: this.itemCreateUserId,
        content_create_user_id: this.contentCreateUserId,
        circulation_create_user_id: circulation.data.create_user_id,
      },
    });
  }

  /**
   * 回覧更新ボタン
   * @param circulation 回覧情報
   */
  protected updateSummaryCirculation(circulation: any) {
    // 更新画面表示
    this.summaryContentDisplayInputComponent.updateInputSummaryCirculation(
      this.contentId,
      circulation.circulation_id
    );
  }

  /**
   * 回覧更新ボタン出力表示判定
   * @param circulation 回覧情報
   * @returns true:ボタン表示、false:ボタン非表示
   */
  public updateSummaryCirculationDisplayFlag(circulation: any): boolean {
    //権限チェック
    if (
      this.loginUser.admit === '1' ||
      this.loginUser.department_code === '99999' ||
      this.loginUser.user_id === String(circulation.data.create_user_id)
    ) {
      return true;
    }
    return false;
  }

  /**
   * 承認者リスト取得
   */
  protected GetApproverList() {
    this.summaryDisplayContentService
      .getGroupMember(this.itemId, this.contentId)
      .subscribe((response) => {
        // 承認者リストが存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 承認者リストのJSONをオブジェクトに格納する
          this.approverList = response.body;
        } else {
          this.approverList = new Array();
        }
      });
  }
}
