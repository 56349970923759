import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  API_URL_INPUT_STOCK_PLANS,
  API_URL_SEARCH_STOCK_PLANS,
  API_URL_STOCK_PLAN,
  API_URL_STOCK_PLANS,
} from 'manager/http-constants_key';
import { PRODUCTION_INSTRUCTION_TEMPLATE } from 'manager/template-constant';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { forkJoin, Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import {
  API_RESPONSE,
  INPUT_INFORMATIONS_API_CONSTANT,
} from 'src/app/shared/constant/api-constant';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { GenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { PRODUCTION_INSTRUCTION_CONSTANT } from './constant';
import { ErrorMessage, InputErrorMessage } from './production-instruction';

@Component({
  selector: 'app-production-instruction',
  templateUrl: './production-instruction.component.html',
  styleUrls: [
    '../stock.component.scss',
    './production-instruction.component.scss',
  ],
})

/**
 * 製作指示画面
 */
export class ProductionInstructionComponent implements OnInit {
  // 画面.入力フォーム
  inputForm: FormGroup = this.formBuilder.group(Object());

  // 画面.一括入力フォーム
  bulkInputForm: FormGroup = this.formBuilder.group(Object());

  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  /** テーブル状態 */
  @ViewChild('table') table: Table;

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_STOCK_PLANS;

  // 検索項目生成テンプレートID
  public searchTemplateId: number =
    PRODUCTION_INSTRUCTION_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面入力項目ヘッダー情報格納先
  inputColumnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 画面.入力項目生成
  inputItemList: any[] = new Array();

  // 入力エラーメッセージ出力オブジェクトリスト(画面表示情報)
  inputErrorMessageList: InputErrorMessage[] = new Array();

  // 検索条件保持
  generateSearchItems: Object;

  constructor(
    private dbOperationService: DbOperationService,
    private messageData: MessageData,
    private commonService: CommonService,
    public datePipe: DatePipe,
    private loadingState: LoadingState,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private exportFileService: ExportFileService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* ヘッダー情報取得処理(画面用) */
    {
      // ヘッダー情報取得
      this.dbOperationService
        .getHeaderList(
          PRODUCTION_INSTRUCTION_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID
        )
        .subscribe((response) => {
          // ヘッダー情報を格納
          this.columnOrder = response.body;

          // 検索処理を実施
          this.searchResult();
        });
    }

    /* 入力項目ヘッダー情報取得処理 */
    {
      // 入力項目ヘッダー情報取得
      this.dbOperationService
        .getData(
          API_URL_INPUT_STOCK_PLANS,
          PRODUCTION_INSTRUCTION_TEMPLATE.INPUT_TEMPLATE_ID
        )
        .subscribe((response) => {
          /* 一括入力項目を生成 */
          // 入力項目項目分ループ
          for (const input of response.body) {
            // フォームコントロールを追加
            this.bulkInputForm.addControl(
              input.column_id,
              this.formBuilder.control(CONSTANT.EMPTY_STRING, Object())
            );
          }

          /* 入力項目ヘッダー情報を格納 */
          this.inputColumnOrder = response.body;
        });
    }
  }

  /**
   * 検索処理
   * @param generateSearchItems 検索条件
   */
  public searchResult(generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    // CSV出力用に検索条件を保持
    this.generateSearchItems = generateSearchItems;

    // 入力エラーメッセージ出力オブジェクトリストを初期化
    this.inputErrorMessageList = new Array();

    /* 製作指示一覧取得処理(画面用) */
    // 非同期同時実行リスト
    const task: Observable<any>[] = [
      /* 製作指示一覧取得処理(画面用) */
      this.dbOperationService.getData(
        API_URL_STOCK_PLANS,
        PRODUCTION_INSTRUCTION_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems
      ),
      /* 製作指示入力項目取得処理 */
      this.dbOperationService.getData(
        API_URL_INPUT_STOCK_PLANS,
        PRODUCTION_INSTRUCTION_TEMPLATE.INPUT_TEMPLATE_ID
      ),
    ];

    // 非同期同時実行
    forkJoin(task).subscribe((responseList) => {
      // 製作指示一覧情報、製作指示入力項目が存在するか否か
      if (this.commonService.checkNoneResponseList(responseList)) {
        // 製作指示一覧情報or製作指示入力項目が存在しない場合

        // 画面.入力フォームを初期化
        this.inputForm = this.formBuilder.group(Object());

        // 空配列をオブジェクトに格納する
        this.searchResultsList = new Array();

        // 空配列をオブジェクトに格納する
        this.inputItemList = new Array();

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();

        // 処理を終了
        return;
      }

      // 画面.入力フォーム情報を一時保存
      const saveInputForm: FormGroup = this.inputForm;

      // 画面.入力フォームを初期化
      this.inputForm = this.formBuilder.group(Object());

      /* 入力フォーム用コントロール作成 */
      // 製作指示一覧分ループ
      for (const searchResults of responseList[0].body) {
        // 子フォームグループを生成
        let childFormGroup = this.formBuilder.group(Object());

        // 製作指示入力項目分ループ
        for (const input of responseList[1].body) {
          // 子フォームグループに入力フォームコントロールを追加
          childFormGroup.addControl(
            input.column_id,
            this.formBuilder.control(CONSTANT.EMPTY_STRING, {})
          );

          /* 前回データを再セット */
          // 一時保存したフォームコントロールが存在するか否か
          if (saveInputForm.controls[searchResults[this.getPkeyColumn()]]) {
            // フォームコントロールが存在する場合

            // 入力フォームに一時保存した値を再セット
            childFormGroup
              .get(input.column_id)
              .setValue(
                saveInputForm.controls[searchResults[this.getPkeyColumn()]].get(
                  input.column_id
                ).value
              );
          }
        }

        // 子フォームグループを入力フォームに格納
        this.inputForm.addControl(
          searchResults[this.getPkeyColumn()],
          childFormGroup
        );
      }

      // 製作指示一覧情報のJSONをオブジェクトに格納する
      this.searchResultsList = responseList[0].body;

      // 製作指示入力項目のJSONをオブジェクトに格納する
      this.inputItemList = responseList[1].body;

      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadSleepEnd(0.3);
    });

    // テーブル状態が存在するか否か
    if (this.table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      this.table.reset();
    }
  }

  /**
   * 一括入力ボタン
   */
  protected onClickBulkInput() {
    // 一括入力ダイアログを表示
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.T00008),
      accept: () => {
        // 一括入力値存在チェックフラグ
        let existenceCheckBulkInput: boolean;

        // 画面入力項目ヘッダー情報分ループ
        for (const inputColumn of this.inputColumnOrder) {
          // 一括入力値を取得
          const bulkInputData = this.bulkInputForm.get(
            inputColumn.column_id
          ).value;

          // 一括入力値が存在するか否か
          if (!bulkInputData) {
            // 一括入力値が存在しない場合

            // 次の画面入力項目ヘッダー情報を処理
            continue;
          }

          // 一括入力値が1件以上存在する為、一括入力値存在チェックフラグをONにする
          existenceCheckBulkInput = true;

          // 画面検索結果一覧分ループ
          for (const searchResults of this.searchResultsList) {
            // 子フォームグループの入力フォームに一括入力値をセット
            this.inputForm.controls[searchResults[this.getPkeyColumn()]]
              .get(inputColumn.column_id)
              .setValue(bulkInputData);
          }
        }

        // 一括入力値存在チェックフラグがOFFのままの場合
        if (!existenceCheckBulkInput) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00018),
              detail: this.commonService.msg(MESSAGE_CODE.E00019),
            })
          );
        }
      },
    });
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected detail(pkeyId: string) {
    // 詳細画面表示
    let generateDisplay: GenerateDisplay = new GenerateDisplay();
    generateDisplay.endPoint = API_URL_STOCK_PLANS;
    generateDisplay.templateId =
      PRODUCTION_INSTRUCTION_TEMPLATE.OUTPUT_TEMPLATE_ID;
    this.generateDisplayItemsComponent.initial(generateDisplay, pkeyId);
  }

  /**
   * 登録確認
   */
  protected checkRegistr() {
    // 入力エラーメッセージ出力オブジェクトリストを初期化
    this.inputErrorMessageList = new Array();

    /* 登録用リクエストオブジェクト作成 */
    // 入力フォームオブジェクトを生成
    let inputRequest: object[] = new Array();
    {
      // 製作指示一覧情報分ループ
      for (const searchResults of this.searchResultsList) {
        // 主キーに紐づく入力値のグループを取得
        const inputValue =
          this.inputForm.controls[searchResults[this.getPkeyColumn()]].value;

        /* 入力値のグループのいずれかに入力されているか判定 */
        {
          // 入力状態フラグ(trueのデータのみ、入力チェック及び登録を実施)
          let inputFlag: boolean;

          // 製作指示入力項目分ループ
          for (const inputItem of this.inputItemList) {
            // 入力カラムのデータが存在しているか否か
            if (
              !inputValue[inputItem[INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID]]
            ) {
              // データが存在していない場合

              continue;
            }

            // 入力チェック及び登録対象のデータと判定
            inputFlag = true;
          }

          // 入力チェック及び登録対象のデータか否か
          if (!inputFlag) {
            // 入力チェック及び登録対象のデータではない場合

            continue;
          }
        }

        // 入力フォームオブジェクトを生成
        let inputForm: object = new Object();

        // 販促資材コードを格納
        inputForm[this.getPkeyColumn()] = searchResults[this.getPkeyColumn()];

        // 入力フォームオブジェクトに対象の入力フォームを格納
        inputForm = Object.assign(inputForm, inputValue);

        // 入力フォームオブジェクトをリストに格納
        inputRequest.push(inputForm);
      }
    }

    // 入力フォームオブジェクトリストが存在するか否か
    if (!inputRequest.length) {
      // 入力フォームオブジェクトリストが存在しない場合

      // 警告メッセージ
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00004),
          detail: this.commonService.msg(MESSAGE_CODE.E00020),
        })
      );

      return;
    }

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00010),
        changeText: '',
      })
    );

    /* 入力確認 */
    {
      // 入力値を確認
      this.dbOperationService
        .insertData(API_URL_STOCK_PLAN, {
          registFlag: 0,
          list: inputRequest,
        })
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd();

          // バリデーションチェック状態を確認
          if (API_RESPONSE.FAIL == response.body[0].result) {
            // チェック状態が異常の場合

            // エラーメッセージを出力
            this.createErrorMessage(response.body[0]);

            // 処理を終了
            return;
          }

          // 登録を実施
          this.registr(inputRequest);
        });
    }
  }

  /**
   * 登録
   * @param inputRequest 登録用リクエストオブジェクト
   */
  private registr(inputRequest: object[]) {
    // 確認ダイアログを表示
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.I00018),
      accept: () => {
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
          new LoadData({
            loadingText: this.commonService.msg(MESSAGE_CODE.I00017),
            changeText: '',
          })
        );

        // 登録を実施
        {
          // 入力値を登録
          this.dbOperationService
            .insertData(API_URL_STOCK_PLAN, {
              registFlag: 1,
              list: inputRequest,
            })
            .subscribe((response) => {
              // 画面ロードフラグをOFF(ロード終了)
              this.loadingState.loadEnd();

              // レスポンスを判定
              if (
                this.messageData.resultResponseToastMessage(
                  response,
                  null,
                  this.commonService.msg(MESSAGE_CODE.I00005)
                )
              ) {
                // レスポンスが正常終了の場合

                // sleep時間を設定(0.3秒)
                const sleep = of('').pipe(delay(300));
                // sleep時間後処理を実施
                sleep.subscribe(() => {
                  // 在庫メンテナンス画面へ遷移
                  this.router.navigate(['pages/stock/stockMaintenanc']);
                });
              }
            });
        }
      },
    });
  }

  /**
   * エラーメッセージを出力
   * @param errorMessage APIから返却されたエラーメッセージ
   */
  private createErrorMessage(errorMessage: any): void {
    //  画面検索結果一覧分ループ
    for (const searchResults of this.searchResultsList) {
      // 主キーに紐づくエラーメッセージを取得
      const errorMessageData =
        errorMessage.error[searchResults[this.getPkeyColumn()]];

      // エラーメッセージが存在しない場合
      if (!errorMessageData) {
        continue;
      }

      // 入力フォームエラー情報オブジェクト生成
      let inputErrorMessage: InputErrorMessage = new InputErrorMessage();

      // 主キーをセット
      inputErrorMessage.itemId = searchResults[this.getPkeyColumn()];

      // エラーメッセージリスト生成
      let errorMessageList: ErrorMessage[] = new Array();

      // 主キーに紐づくエラーメッセージでループ
      for (const error of errorMessageData) {
        // エラーメッセージオブジェクト生成
        let errorMessage: ErrorMessage = new ErrorMessage();

        // カラムIDをセット
        errorMessage.columnId =
          error[INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID];

        // カラム名称をセット
        errorMessage.columnName = this.commonService.getArrayObjectValue(
          this.inputItemList,
          INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID,
          INPUT_INFORMATIONS_API_CONSTANT.COLUMN_NAME,
          error[INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID]
        );

        // エラーメッセージをセット
        errorMessage.message = error.message;

        // エラーメッセージリストに格納
        errorMessageList.push(errorMessage);
      }

      // エラーメッセージリストをセット
      inputErrorMessage.errorMessageList = errorMessageList;

      // 入力フォームエラー情報オブジェクトをリストに格納
      this.inputErrorMessageList.push(inputErrorMessage);
    }
  }

  /**
   * エラー項目チェック
   * @param pkeyId 主キー
   * @param column_id 対象項目
   * @returns true:エラーメッセージが存在する場合 false:エラーメッセージが存在しない場合
   */
  protected checkErrorItem(pkeyId: string, columnId: string): boolean {
    // 入力エラーメッセージ出力オブジェクトリスト分ループ
    for (const inputErrorMessage of this.inputErrorMessageList) {
      // 入力エラーメッセージ出力オブジェクトの販促資材IDと引数.販促資材コードが一致するか否か
      if (pkeyId == inputErrorMessage.itemId) {
        // 条件が一致する場合

        // エラーメッセージリストのエラーメッセージ存在チェック
        // 対象項目が存在する場合、trueを返却
        return inputErrorMessage.errorMessageList.some(
          (errorMessage) => errorMessage.columnId === columnId
        );
      }
    }

    return false;
  }

  /**
   * CSV出力
   */
  protected exportCSV() {
    // csvファイル名の設定
    const fileName =
      PRODUCTION_INSTRUCTION_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      null,
      API_URL_STOCK_PLANS,
      PRODUCTION_INSTRUCTION_TEMPLATE.CSV_TEMPLATE_ID,
      this.generateSearchItems
    );
  }

  /**
   * プライマリーカラムを取得
   */
  protected getPkeyColumn(): string {
    return this.commonService.getPkeyColumn(this.columnOrder);
  }
}
